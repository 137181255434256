import * as React from "react"
import Layout from "../components/layout"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"

import { graphql, Link } from "gatsby"
import Button from "react-bootstrap/Button"

const Team = ({ data }) => {

    return (
      <Layout>
        <SEO title='Team'/>
        <article>
          <h1>Team</h1>
          <p className='lead'>Each crisis is different and, in order to survive them,  organisations must be well-prepared and trained. However, because crises are rare, very few people have experienced and managed them.</p>
          <p className='lead'>The BHK partners have each lived through quite a number of such situations, enhancing their proficiency with each case.</p>

            <Row xs={1} md={2} className='justify-content-center'>
              {data.people.nodes.map(person =>
                <Col className='mb-4' key={person.id}>
                  <Link className='text-decoration-none' to={person.slug}>
                    <Card className='h-100 bhk-action-card'>
                      <Card.Body className='d-flex flex-column'>
                        <Card.Title>
                          <Link className='text-body bhk-action-arrow bhk-action-arrow-dark text-decoration-none d-block' to={person.slug}>
                            {person.name}
                          </Link>
                        </Card.Title>
                        <Row>
                          <Col>
                            <Card.Text>
                              {person.description}
                            </Card.Text>
                          </Col>
                          <Col xs={4}>
                            <GatsbyImage
                              image={person.photo.gatsbyImageData}
                              alt={person.name}
                              className='rounded' />
                          </Col>
                        </Row>


                        <Link className='mt-auto' to={person.slug}>
                          <Button className='mt-3' as="span" block>Read Biography</Button>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )}
            </Row>
        </article>
      </Layout>
    );
}

export default Team;

export const query = graphql`{
  people: allContentfulPerson(sort: {fields: index}, filter: {active: {eq: true}}) {
    nodes {
      id
      email
      slug
      name
      description
      bio {
        raw
      }
      photo {
        gatsbyImageData(layout: CONSTRAINED, height: 286, width: 220)
      }
    }
  }
}
`
